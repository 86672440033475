import React from "react";
import ReactDatetime from "react-datetime";
import { Button, Col, FormGroup, Row,Spinner } from "reactstrap";

import NotificationAlert from "react-notification-alert";
import moment from "moment";
class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      timeslot: ""
    };
  }

  notify = (place, color) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Please select the <b>Date and Timeslot</b> to continue.
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  isValidated = () => {
    if (this.props.date === "" || this.state.timeslot === "") {
     
      this.notify("tr", 3);
      return false;
    } else {
      this.props.selectDateTime(this.state.date, this.state.timeslot);
      return true;
    }
  };

  updateState = (value, name) => {
    if (name == "date") {
      this.props.fetchTimeslotsByDate(value, this.props.procedureId,this.props.clientId);
      this.props.selectDateTime(value, null);
      this.setState({ timeslot: "" });
      this.setState({ [name]: value });
    }else{
      this.setState({ [name]: value },()=>this.props.nextButtonClick());
    }
    
  };

  validDate = current => {
    let yesterday = ReactDatetime.moment().subtract(1, "day");
    let maxdate = ReactDatetime.moment().add(16, "day");
    return current.isAfter(yesterday) &&current.isBefore(maxdate) ;
  };

  componentWillReceiveProps(newProps){
    if(newProps.date!=this.state.date){
      this.setState({
        date:newProps.date
      })
    }
   
  }

  
  render() {
    console.log('timeslots: ',this.props.timeslots)
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <h5 className="info-text">Select the preferred date</h5>
        <Row className="justify-content-center">
          <Col md="4">
            <FormGroup>
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: "Select the date"
                }}
                isValidDate={this.validDate}
                timeFormat={false}
                closeOnSelect={true}
                value={this.state.date} 
                //defaultValue={this.props.date!=null?this.props.date:new Date()}
                onChange={value => {
                  this.updateState(value.format("YYYY-MM-DD"), "date");
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5 className="info-text">Select the timeslot</h5>
        <Row className="mt-3 justify-content-center">
        {this.props.isLoading ? (
              <Col className="mr-auto ml-auto text-center" sm="10" md="5">
              <h6 className="info-warning">
                Loading Timeslots
              </h6>
                      {/* <Spinner type="grow" color="primary" />
                      <Spinner type="grow" color="secondary" />
                      <Spinner type="grow" color="success" />
                      <Spinner type="grow" color="danger" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="info" /> */}
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="dark" />
                      <Spinner type="grow" color="dark" />
              </Col>
            ) :
            (
          <Col xs="12" sm="12" md="10" lg="8" className="text-center">
            {Object.keys(this.props.timeslots).length == 0 && this.state.date == "" ? (
              <h6 className="info-warning">
                Please select the date to view timeslots
              </h6>
            ) :  !this.props.timeslots.timeslot||Object.keys(this.props.timeslots.timeslot).length == 0 && this.state.date != ""||this.props.timeslots.isHoliday ? (
              <h6 className="info-warning">
                We have not started accepting appointments on this day
              </h6>
            ) :
            Object.keys(this.props.timeslots.timeslot).sort().map((key, index) => (
              <Button
                className={`btn-round ${
                  this.state.timeslot != "" &&
                  this.props.timeslots.timeslot[key].label == this.state.timeslot.label
                    ? "active"
                    : ""
                }`}
                color="default"
                disabled={this.props.timeslots.timeslot[key].isBooked||moment(this.props.timeslots.timeslot[key].startTime).isBefore(new Date())}
                outline
                onClick={value => {
                  this.updateState( this.props.timeslots.timeslot[key], "timeslot");
                }}
              >
                { this.props.timeslots.timeslot[key].label}
              </Button>
            ))}
            </Col>)}
        </Row>
      </>
    );
  }
}

export default Wizard;
