import { FETCH_PROCEDURES, FETCH_TIMESLOTS_FOR_DATE, EMPTY_TIMESLOTS, START_LOADING, FETCH_CLIENT_LOGO } from "./types";
import { fetchProceduresFromDb, fetchTimeSlotsFromDb, fetchClientDetailsFromDb } from "../services";

export const fetchProcedures = (clientId) => {
  return async dispatch => {
    let procedures = await fetchProceduresFromDb(clientId);
    console.log(procedures)
    dispatch({
      type: FETCH_PROCEDURES,
      procedures
    });
  };
};

export const fetchClientDetails = (clientId) => {
  return async dispatch => {
    let clientDetails = await fetchClientDetailsFromDb(clientId);
    console.log(clientDetails)
    dispatch({
      type: FETCH_CLIENT_LOGO,
      clientDetails
    });
  };
};

export const fetchTimeSlots = (date,procedureId,clientId) => {
    return async dispatch => {
      dispatch({
        type: START_LOADING
      });
      let timeslots = await fetchTimeSlotsFromDb(date,procedureId,clientId);
      dispatch({
        type: FETCH_TIMESLOTS_FOR_DATE,
        timeslots
      });
    };
  };

  export const emptyTimeslots = () => {
    return async dispatch => {
      dispatch({
        type: EMPTY_TIMESLOTS
      });
    };
  };
