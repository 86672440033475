/**
 * reducer
 */
import { combineReducers } from "redux";

import isLoading from "./is-loading";
import procedures from "./procedures";
import timeslots from "./timeslots";
import clientDetails from "./clientDetails";

const reducers = combineReducers({
  timeslots,
  isLoading,
  procedures,
  clientDetails
});

export default reducers;
