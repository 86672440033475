import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import "./styles/app.scss";
import Wizard from "./views/Wizard";
import createHistory from "history/createBrowserHistory";

const history = createHistory();
class App extends React.Component {
  componentWillMount(){
    const origin = window.location.origin;
    const baseUrl = window.location.href;
    const pathname = window.location.pathname;
    // console.log(origin);
    // console.log(baseUrl);
    // console.log(pathname);
    // console.log(window.location.hostname);
    // console.log(window.location.protocol);
    const redirectUrl = baseUrl.substring(0, baseUrl.length - 1)
    if(baseUrl.endsWith("/") && redirectUrl != origin){
      console.log('Redirecting to url');
      window.location.href = redirectUrl;
    }else{
      console.log('no change');
    }
  }
  render() {
    return (
      <div className="wrapper">
        <div className="main-panel">
          <Wizard history={history}/>
        </div>
      </div>
    );
  }
}


export default App;