import classnames from "classnames";
import React from "react";
import NotificationAlert from "react-notification-alert";
import { Col, Row } from "reactstrap";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      design: false,
      code: false,
      develop: false,
      procedure: "",
      isError: false
    };
  }
  clickChoice = procedure => {
    this.setState({
      procedure: procedure
    },()=>this.props.nextButtonClick());

    
  };

  notify = (place, color) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            Please select the <b>Procedure</b> to continue
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  isValidated = () => {
    if (this.state.procedure === "") {
      this.setState({ isError: true });
      this.notify("tr", 3);
      return false;
    } else {
      this.props.emptyTimeslots();
      this.props.selectProcedure(this.state.procedure.procedureId,this.state.procedure)
      this.setState({ isError: false });
      return true;
    }
  };
  render() {
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <h5 className={"info-text"}>Please select the Procedure</h5>
        <Row className="justify-content-center">
          <Col lg="10">
            <Row>
              {this.props.procedures.map((procedure, index) => (
                <Col sm="4">
                  <div
                    className={classnames("choice", {
                      active: this.state.procedure.name === procedure.name
                    })}
                    data-toggle="wizard-checkbox"
                    onClick={() => this.clickChoice(procedure)}
                  >
                    <input
                      defaultValue= {procedure.name}
                      name="jobb"
                      type="checkbox"
                      defaultChecked={
                        this.state.procedure.name === procedure.name
                      }
                    />
                    <div className="icon">
                      <i className={`fa ${procedure.iconName}`} />
                    </div>
                    <h6>{ procedure.name}</h6>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}


export default Wizard