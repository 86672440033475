import { FETCH_PROCEDURES } from "../actions/types";

const INITIAL_STATE = []

 
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_PROCEDURES:
            return action.procedures
        default:
         return state;
    }
}