import React from "react";
import classnames from "classnames";
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import FileUpload from "../FileUpload";
import NotificationAlert from "react-notification-alert";
import defaultImage from "../file.png";
// core components
//import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      comment:"",
      firstnameState: "",
      lastnameState: "",
      emailState: "",
      phoneState: "",
      commentState: "",
      files: [],
      fileInfo: [],
      duplicate:false
      // file: null,
      // fileType:false,
      // imagePreviewUrl: defaultImage
    };
  }

  imageStateChange = (index, file, fileType, imagePreviewUrl) => {
    let fileInfo = [...this.state.fileInfo];
    fileInfo.splice(index, 1, { file, fileType, imagePreviewUrl });
    this.setState({
      // file:file,
      // fileType:fileType,
      // imagePreviewUrl:imagePreviewUrl
      fileInfo
    })
  }
  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    console.log(value.length);
    if (value.length > length) {
      return false;
    }
    return true;
  };


  notify = (place, color, message) => {
    // var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  fileValidation = (newfile) => {
    let newFileName = newfile.name;
    let fileName = this.state.files[0].name;
    let files = [...this.state.files];
    if(newFileName === fileName){
      this.notify("tr", 3, " Duplicate files cannot be attached");
      this.setState({duplicate:true}, () =>{
      
      })
      
    }else{
      files.splice(1,1, newfile);
      this.setState({ files },()=>{
        console.log('files', this.state.files)
      })
    }
  }

  fileValidationForZeroPosition = (newfile) =>{
    if(this.state.files.length>1){
      //console.log('We the people')
      let newFileName = newfile.name;
      let fileName = this.state.files[1].name;
      let files = [...this.state.files];
        if(newFileName === fileName){
        this.notify("tr", 3, " Duplicate files cannot be attached");
        this.setState({duplicate:true})
      }else{
        let files = this.state.files;
        if(this.state.files.length){
          files.splice(0, 1, newfile);
        }else{
          files[0] = newfile;
        }
        this.setState({ files })
      }
    }else{
      let files = this.state.files;
      if(this.state.files.length){
        files.splice(0, 1, newfile);
      }else{
        files[0] = newfile;
      }
      this.setState({ files })
    }
  }


  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    console.log('name:', stateName)
    console.log('value:', event.target.value)
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        console.log("heehehhehesdsdsdsdsds", stateName);
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          console.log("heehehhehe", stateName);
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };
  isValidated = () => {
    if (
      this.state.firstnameState === "has-success" &&
      this.state.lastnameState === "has-success" &&
      this.state.emailState === "has-success" &&
      this.state.phoneState === "has-success" &&
      this.state.commentState === "has-success"
    ) {
      let contactDetails = {
        firstName: this.state.firstname,
        lastName: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        comment: this.state.comment,
        files: this.state.files
      };
      this.props.submitAppointmentRequest(contactDetails);
      return true;
    } else {
      if (this.state.firstnameState !== "has-success") {
        this.setState({ firstnameState: "has-danger" });
      }
      if (this.state.lastnameState !== "has-success") {
        this.setState({ lastnameState: "has-danger" });
      }
      if (this.state.emailState !== "has-success") {
        this.setState({ emailState: "has-danger" });
      }
      if (this.state.phoneState !== "has-success") {
        this.setState({ phoneState: "has-danger" });
      }
      if (this.state.commentState !=="has-success") {
        this.setState({ commentState:"has-danger"})
      }
      return true;
    }
  };

  keyDown = (event)=>{
      console.log('key', event.key)
      if(event.key==='.' || event.key==='+' || event.key==='-' || event.key === 'e' ){
        event.preventDefault();
    }
  }

  removeFile = (index)=>{
    let files = [...this.state.files];
    let fileInfo = [...this.state.fileInfo];
    console.log('file info', fileInfo)
    files.splice(index,1);
    fileInfo.splice(index, 1);
    this.setState({files, fileInfo, duplicate:false}, 
      () => {
        console.log('fileszzzzzzz', this.state.fileInfo);
      })
  }

  removeFileFromInfo = (index)=>{
    let files = [...this.state.files];
    let fileInfo = [...this.state.fileInfo];
    //console.log('file info', fileInfo)
      files=[];
      fileInfo=[];
    
      //files.splice(index,1);
      fileInfo.splice(index, 1);
    this.setState({files, fileInfo, duplicate:false})
  }

  render() {
    return (
      <>
      <NotificationAlert ref="notificationAlert" />
        <h5 className="info-text">Add contact information.</h5>
        <Row className="justify-content-center">
          <Col sm="5">
            <InputGroup
              className={classnames(this.state.firstnameState, {
                "input-group-focus": this.state.firstnameFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-single-02" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="firstname"
                placeholder="First Name (required)"
                type="text"
                onChange={e => this.change(e, "firstname", "length", 50)}
                onFocus={e => this.setState({ firstnameFocus: true })}
                onBlur={e => this.setState({ firstnameFocus: false })}
              />
              {this.state.firstnameState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
          <Col sm="5">
            <InputGroup
              className={classnames(this.state.lastnameState, {
                "input-group-focus": this.state.lastnameFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-circle-10" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="lastname"
                placeholder="Last Name (required)"
                type="text"
                onChange={e => this.change(e, "lastname", "length", 50)}
                onFocus={e => this.setState({ lastnameFocus: true })}
                onBlur={e => this.setState({ lastnameFocus: false })}
              />
              {this.state.lastnameState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>

          <Col className="mt-3" sm="5">
            <InputGroup
              className={classnames(this.state.emailState, {
                "input-group-focus": this.state.emailFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-send" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="email"
                placeholder="Email (required)"
                type="email"
                onChange={e => this.change(e, "email", "email")}
                onFocus={e => this.setState({ emailFocus: true })}
                onBlur={e => this.setState({ emailFocus: false })}
              />
              {this.state.emailState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
          <Col className="mt-3" sm="5">
            <InputGroup
              className={classnames(this.state.phoneState, {
                "input-group-focus": this.state.phoneFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-send" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="phone"
                placeholder="Phone (required)"
                type="number"
                onKeyDown={this.keyDown}
                onChange={e => this.change(e, "phone", "length", 10)}
                onFocus={e => this.setState({ phoneFocus: true })}
                onBlur={e => this.setState({ phoneFocus: false })}
              />
              {this.state.phoneState === "has-danger" ? (
                <label className="error">Please enter valid 10 digit phone no.</label>
              ) : null}
            </InputGroup>
          </Col>

          <Col className="mt-3" sm="10">
            <InputGroup
              className={classnames(this.state.phoneState, {
                "input-group-focus": this.state.phoneFocus
              })}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="nc-icon nc-send" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="comment"
                placeholder="Please mention body part to scan and provide any additional information"
                type="textarea"
                onChange={e => this.change(e, "comment", "length", 100)}
                onFocus={e => this.setState({ phoneFocus: true })}
                onBlur={e => this.setState({ phoneFocus: false })}
              />
              {this.state.phoneState === "has-danger" ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>

         
               <Col className="mt-3"  sm="5">
              <FileUpload
                index = {0}
                removeFile={this.removeFile}
                removeFileFromInfo={this.removeFileFromInfo}
                fileInfo={this.state.fileInfo[0] ? this.state.fileInfo[0] : null}
                imageStateChange= {this.imageStateChange}
                fileSizeAlert={this.props.fileSizeAlert}
                duplicate={this.state.duplicate}
                updateState={newfile =>{
                  
                  this.fileValidationForZeroPosition(newfile);
                }}
              />
               </Col>
               <Col className="mt-3"  sm="5">
               <FileUpload
                 index = {1}
                 removeFile={this.removeFile}
                 removeFileFromInfo={this.removeFileFromInfo}
                 duplicate={this.state.duplicate}
                 fileInfo={this.state.fileInfo[1] ? this.state.fileInfo[1] : null}
                 imageStateChange= {(this.imageStateChange)}
                 fileSizeAlert={this.props.fileSizeAlert}
                 updateState={newfile => this.fileValidation(newfile)}
              />
               </Col>
         
        </Row>
      </>
    );
  }
}

export default Wizard;
