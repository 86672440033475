import {
  IS_LOADING,
  FETCH_TIMESLOTS_FOR_DATE,
  START_LOADING
} from "../actions/types";

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading;
    case START_LOADING:
      return true;
    case FETCH_TIMESLOTS_FOR_DATE:
      return false;
    default:
      return state;
  }
};
