import React from "react";
import ReactWizard from "./react-wizard-custom";
import { Col, Card, CardBody, CardHeader, Button,Spinner } from "reactstrap";
import { Redirect } from "react-router-dom";
// wizard steps
import Procedures from "./WizardSteps/procedures";
import Timeslots from "./WizardSteps/timeslots";
import ContactDetailsForm from "./WizardSteps/contact-details";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchProcedures,
  fetchTimeSlots,
  emptyTimeslots,
  fetchClientDetails
} from "../redux/actions";
import { submitAppointmentRequestToDb } from "../redux/services";
import NotificationAlert from "react-notification-alert";
import moment from "moment";
class Wizard extends React.Component {
  state = {
    procedureId: "",
    clientId:"",
    procedure: {},
    date: null,
    timeslot: null,
    booked: false,
    isAccepted: false,
    isAdmin: false,
    isLoading: false
    // firstName
    // 		lastName
    // 		email
    // 		phone
    // 		procedureId
    // 		bookingDate
    // 		bookingTimeSlot
    // 		isApproved
    // 		isRejected
  };

  async componentWillMount() {
    let clientId = window.location.pathname.replace("/","");
    console.log("clientId",clientId)
    this.setState({clientId})
    this.props.fetchProcedures(clientId);
    await this.props.fetchClientDetails(clientId);
  }

  selectProcedure = (procedureId, procedure) => {
    let date = moment(new Date()).format("YYYY-MM-DD")
    this.setState({ procedureId, procedure ,date});
    this.props.fetchTimeslotsByDate(date, procedureId,this.state.clientId);
    console.log(this.state);
  };

  selectDateTime = (date, timeslot) => {
    this.setState({ date, timeslot }, () => console.log(this.state));
  };
  notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  submitAppointmentRequest = async ({
    firstName,
    lastName,
    email,
    phone,
    comment,
    files
  }) => {
    if (!this.state.isLoading) {
      this.setState({ firstName, lastName, email, phone,comment });
      let request = { ...this.state, firstName, lastName, email, phone,comment,files:[] };

      this.setState({ isLoading: true });
      let result = await submitAppointmentRequestToDb(request, files,this.state.clientId);
      if (result.request) {
        this.props.emptyTimeslots();
        // this.notify("tr", 2, "Appointment Request is submitted successfully");
        this.setState({
          procedureId: "",
          procedure: {},
          date: null,
          timeslot: null,
          booked: true,
          isLoading: false
        });
        if(!result.files){
          this.notify(
            "tr",
            2,
            "Booking request submitted successfully but error occurred while uploading files."
          );
        }
      } else {
        this.setState({ isLoading: false });
        this.notify(
          "tr",
          3,
          "Error while submitting appointment request. please try again."
        );
      }
    }
  };

  fileSizeAlert = type => {
    if (type) {
      this.notify(
        "tr",
        3,
        "File should be either image or pdf. Please try again"
      );
    } else {
      this.notify("tr", 3, "File size cannot be greater than 5MB");
    }
  };

  render() {
   // console.log('client Details:', this.state.client);
    
    const steps = [
      {
        stepName: "Select Procedure",
        stepIcon: "fa fa-plus-square",
        component: Procedures,
        stepProps: {
          procedures: this.props.procedures,
          selectProcedure: this.selectProcedure,
          emptyTimeslots:this.props.emptyTimeslots,
          clientId:this.state.clientId
        }
      },
      {
        stepName: "Select Timeslot",
        stepIcon: "fa fa-calendar-plus-o",
        component: Timeslots,
        stepProps: {
          date: this.state.date,
          timeslot: this.state.timeslot,
          timeslots: this.props.timeslots,
          selectDateTime: this.selectDateTime,
          fetchTimeslotsByDate: this.props.fetchTimeslotsByDate,
          procedureId: this.state.procedureId,
          isLoading:this.props.isLoading,
          clientId:this.state.clientId
        }
      },

      {
        stepName: "Appointment Details",
        stepIcon: "fa fa-user-md",
        component: ContactDetailsForm,
        stepProps: {
          submitAppointmentRequest: this.submitAppointmentRequest,
          procedureId: this.state.procedureId,
          isLoading: this.state.isLoading,
          fileSizeAlert: this.fileSizeAlert,
          clientId:this.state.clientId
        }
      }
    ];
    return (
      <>
        <NotificationAlert ref="notificationAlert" />
        <div className="content">
          {!this.state.booked ? (
            this.state.isLoading ? (
              <Col className="mr-auto ml-auto text-center" sm="10" md="5">
                <Card>
                  <CardHeader>
                    <h3 className="card-title">
                      Submiting Appointment Request. Please wait
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <div className="clearfix" />
                    <p className="card-description">
                      <Spinner type="grow" color="primary" />
                      <Spinner type="grow" color="secondary" />
                      <Spinner type="grow" color="success" />
                      <Spinner type="grow" color="danger" />
                      <Spinner type="grow" color="warning" />
                      <Spinner type="grow" color="info" />
                      <Spinner type="grow" color="dark" />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <Col className="mr-auto ml-auto" md="10">
                {
                  this.props.clientDetails.logo && this.props.clientDetails.logo!=""?(
                    <div className="logo-style" style={{}}>
                      <img src={this.props.clientDetails.logo} alt="..." />
                    </div>
                  ):null
                }
                <ReactWizard
                  steps={steps}
                  navSteps
                  validate
                  title="Book an Appointment"              
                  //description="This information will let us know more about you."
                  headerTextCenter
                  finishButtonClasses="btn-wd"
                  nextButtonClasses="btn-wd"
                  previousButtonClasses="btn-wd"
                  color="info"
                />
              </Col>
            )
          ) : (
            (this.state.clientId === "hri" || this.state.clientId === "par" || this.state.clientId === "arg" || this.state.clientId === "tng")?window.location.replace("https://www.specialistmedicalimaging.com.au/thank-you"):(
            <Col className="mr-auto ml-auto text-center" sm="10" md="5">
              <Card>
                <CardHeader>
                  <h3 className="card-title">Appointment Request</h3>
                </CardHeader>
                <CardBody>
                  <div className="clearfix" />
                  <p className="card-description">
                    <h6 className="text-success">
                      Appointment request has been submitted successfully.{" "}
                    </h6>
                    <h6 className="text-info">
                      You will receive email/call once appointment gets
                      confirmed
                    </h6>
                    <div className="clearfix" />
                    <h6 className="text-default">Thank you</h6>
                    {/* <div className="icon">
                      <i className={`fa fa-user`} />
                    </div> */}
                    <Button
                      color="default"
                      onClick={() => this.setState({ booked: false })}
                    >
                      Close
                    </Button>
                  </p>
                </CardBody>
              </Card>
            </Col>
            )
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  procedures: state.procedures,
  timeslots: state.timeslots,
  isLoading:state.isLoading,
  clientDetails: state.clientDetails
});

const mapDispatchToProps = dispatch => ({
  fetchProcedures: bindActionCreators(fetchProcedures, dispatch),
  emptyTimeslots: bindActionCreators(emptyTimeslots, dispatch),
  fetchTimeslotsByDate: bindActionCreators(fetchTimeSlots, dispatch),
  fetchClientDetails: bindActionCreators(fetchClientDetails,dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wizard);
