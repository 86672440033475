
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import Spinner from 'react-bootstrap/Spinner';

import defaultImage from "./file.png";
//import defaultAvatar from "assets/img/placeholder.jpg";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   file: null,
    //   fileType: false,
    //   imagePreviewUrl: defaultImage
    // };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    console.log('file22', file);
    if(file){
      if (file.size > 5000000) {
        this.props.fileSizeAlert()
      } else {
        let type = false
        let reg = /(.*?)\.(jpg|bmp|jpeg|png)$/;
        if (file.name.match(reg)) {
  
          type = "image";
        }
        reg = /(.*?)\.(pdf)$/;
        if (file.name.match(reg)) {
  
          type = "pdf";
        }
        if (type) {
          reader.onloadend = () => {
            this.props.imageStateChange(this.props.index, file, type, reader.result)
            /* this.setState({
              file: file,
              fileType:type,
              imagePreviewUrl: reader.result
            }); */
          };
          this.props.updateState(file)
          reader.readAsDataURL(file);
        } else {
          this.props.fileSizeAlert(true)
        }
  
      }
    }
    

  }

  handleClick() {
    this.refs.fileInput.click();
  }

  handleRemove() {
    /* this.setState({
      file: null,
      imagePreviewUrl: defaultImage
    }); */
    // this.props.imageStateChange(null, false, defaultImage);
    console.log(this.props.index)
    this.props.removeFile(this.props.index)
    this.refs.fileInput.value = null;
  }

  handleRemoveForDuplicate = () => {
    console.log(this.props.index)
    this.props.removeFileFromInfo(this.props.index)
    this.refs.fileInput.value = null;
  }

  componentWillMount() {
    //this.props.imageStateChange(null,false,defaultImage);

  }

  componentWillReceiveProps(newProps) {
    //console.log("newProps", newProps);
    if(this.props.fileInfo!==null){
      if(this.props.duplicate){
        console.log('Hahahaha');
        this.handleRemoveForDuplicate();
        //newProps.imageStateChange(newProps.index,null,false,defaultImage);
        return null;
      }
    }
    if (newProps.file == undefined) {
      /* this.setState({
          file: null,
          fileType:false,
          imagePreviewUrl: defaultImage
        }); */
      //newProps.imageStateChange(null,false,defaultImage);
      console.log("newProps", newProps);
    } else {
      let reader = new FileReader();
      let type = false
      let reg = /(.*?)\.(jpg|bmp|jpeg|png)$/;
      if (newProps.file.name.match(reg)) {

        type = "image";
      }
      reg = /(.*?)\.(pdf)$/;
      if (newProps.file.name.match(reg)) {

        type = "pdf";
      }
      if (type) {
        reader.onloadend = () => {
          /*  this.setState({
             file: newProps.file,
             fileType:type,
             imagePreviewUrl: reader.result
           }); */
          newProps.imageStateChange(newProps.index,newProps.file, type, reader.result);
        };
        reader.readAsDataURL(newProps.file);
      }
    }
  }
  render() {

    console.log('current file', this.props.fileInfo);

    
    /* if(this.state.file){
     // console.log('current file', this.state.file.name);
      if(this.props.files.length>1){
       // console.log('props all files', this.props.files[0].name);
         let file1 = this.props.files[0].name;
         let file2 = this.state.file.name
        if(file1 === file2){
          console.log('gud-bud');
          //return;
        }else{
          console.log('no gud bud');
        }
      }
    } */
    return (
      <div className="fileinput text-center">

        <input type="file" onChange={this.handleImageChange} ref="fileInput" />
        <div className={"thumbnail" + (this.props.fileInfo == null ? "small-icon" : "")}>
          { this.props.fileInfo && this.props.fileInfo.fileType == 'image' ? 
            <img src={this.props.fileInfo.imagePreviewUrl} alt="..." width={"250px"} height={"200px"} /> :
            this.props.fileInfo && this.props.fileInfo.fileType == 'pdf' ? 
            <iframe src={this.props.fileInfo.imagePreviewUrl} width="100%" height="250px"></iframe> :
            <img src={defaultImage} alt="..." width={"100px"} height={"100px"} />
          }
        </div>
        <div>
          {this.props.fileInfo === null ? (

            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.index == 0 ? "Attach referral document" : "Attach additional document"}
            </Button>
          ) : (
              <span>
                <Button className="btn-round" onClick={() => this.handleClick()}>
                  Change
              </Button>
                {this.props.avatar ? <br /> : null}
                <Button
                  color="danger"
                  className="btn-round"
                  onClick={() => this.handleRemove()}
                >
                  <i className="fa fa-times" />
                  Remove
              </Button>
              </span>
            )}
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = {
  avatar: PropTypes.bool
};

export default FileUpload;
