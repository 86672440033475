import axios from "axios";

// const url = "https://us-central1-med-app-6be5e.cloudfunctions.net/";
const eurl = "https://us-central1-medical-booking-6c295.cloudfunctions.net/app/";
export const fetchProceduresFromDb = clientId => {
  return axios
    .post(`${eurl}fetchProcedures`, {
      data: {
        clientId
      }
    })
    .then(function(response) {
      if (response.status == 200) {
        return response.data.data.procedures;
      }
      return [];
    })
    .catch(function(error) {
      console.log(error);
      return [];
    });
};

export const fetchClientDetailsFromDb = clientId => {
  return axios
    .post(`${eurl}fetchClientNameAndLogo`, {
      data: {
        clientId
      }
    })
    .then(function(response) {
      if (response.status == 200) {
        return response.data.data.clientNameAndLogo;
      }
      return [];
    })
    .catch(function(error) {
      console.log(error);
      return [];
    });
};

export const fetchTimeSlotsFromDb = (date, procedureId, clientId) => {
  return axios
    .post(`${eurl}fetchTimeSlotByDate`, {
      data: {
        date,
        procedureId,
        clientId
      }
    })
    .then(function(response) {
      if (response.status == 200) {
        console.log("response.data.data", response.data.data.data.timeslot);
        return response.data.data.data;
      }
      return [];
    })
    .catch(function(error) {
      console.log(error);
      return [];
    });
};

export const uploadFile = formData => {
  return axios({
    method: "post",
    url: `${eurl}uploadFile`,
    data: formData,
    config: { headers: { "Content-Type": "multipart/form-data" } }
  })
    .then(function(response) {
      if (response.status == 200) {
        return response.data.data.url;
      }
      return false;
    })
    .catch(function(error) {
      return false;
    });
};

export const submitAppointmentRequestToDb = async (
  request,
  files,
  clientId
) => {
  // console.log('request is as follows:',request);
  let result = await axios
    .post(`${eurl}submitBookingRequest`, {
      data: {
        booking: request,
        clientId
      }
    })
    .then(function(response) {
      if (response.status == 200) {
        return response.data.data.bookingRequestId;
      }
      return false;
    })
    .catch(function(error) {
      return false;
    });

  if (result) {
    let promises = [];
    if (files.length > 0) {
      files.map((file, index) => {
        let bodyFormData = new FormData();
        bodyFormData.append("clientId", JSON.stringify(clientId));
        bodyFormData.append("bookingId", JSON.stringify(result));
        bodyFormData.append("file", files[index]);
        let promise = uploadFile(bodyFormData);
        promises.push(promise);
      });

      return Promise.all(promises)
        .then(function(values) {
          return { request: true, files: true };
        })
        .catch(function(err) {
          return { request: true, files: false };
        });
    } else {
      return { request: true, files: true };
    }
  } else {
    return { request: false, files: false };
  }
};
