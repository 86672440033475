import { FETCH_CLIENT_LOGO } from "../actions/types";

const INITIAL_STATE = {}

 
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_CLIENT_LOGO:
            return action.clientDetails;
        default:
            return state;
    }
}