import { FETCH_TIMESLOTS_FOR_DATE, EMPTY_TIMESLOTS } from "../actions/types";

const INITIAL_STATE = {}

 
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_TIMESLOTS_FOR_DATE:
            return action.timeslots
        case EMPTY_TIMESLOTS:
        return {}
        default:
         return state;
    }
}